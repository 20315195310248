/* eslint-disable */
import { getIn } from 'formik';

// MUI
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

const FilePicker = (props) => {
  const {
    formik,
    getApiError,
    label,
    name,
    onChange,
    showFileBrowser,
  } = props;

  return (
    <TextField
      error={Boolean(getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name)))}
      fullWidth
      helperText={getApiError(name) || (getIn(formik.touched, name) && getIn(formik.errors, name))}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => showFileBrowser && showFileBrowser(name)}
            >
              <OpenInBrowserIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      name={name}
      onBlur={formik.handleBlur}
      onChange={onChange || formik.handleChange}
      value={getIn(formik.values, name)}
      onDoubleClick={() => showFileBrowser && showFileBrowser(name)}
    />
  );
};

export default FilePicker;
