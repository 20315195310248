export const canAddAccess = (learningGroup) => {

    const type = learningGroup['@type'] ?? null;
    return ['SchoolLearningGroup', 'CustomLearningGroup'].includes(type);
}

export const editPath = (learningGroup) => {

    const type = learningGroup['@type'] ?? null;
    const { id = null } = learningGroup;

    if (id === null || type === null) {
        return null;
    }

    switch (type) {
        case 'SchoolLearningGroup':
            return `/admin/school-learning-groups/edit/${id}`

        case 'CustomLearningGroup':
            return `/admin/custom-learning-groups/edit/${id}`

        default:
            return null;
    }
}

export const editInfinityPath = (learningGroup) => {

    const type = learningGroup['@type'] ?? null;
    const { id = null } = learningGroup;

    if (id === null || type === null) {
        return null;
    }

    switch (type) {
        case 'SchoolLearningGroup':
            return `/admin/infinity/school-learning-groups/edit/${id}`

        case 'CustomLearningGroup':
            return `/admin/infinity/custom-learning-groups/edit/${id}`

        default:
            return null;
    }
}
