/**
 * Modified version of the MDPR2 TimelineItem to allow rich content
 */
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';
import WarningIcon from '@mui/icons-material/Warning';

// Material Dashboard 2 PRO React components
import MDBox from 'mdpr2/components/MDBox';
import MDTypography from 'mdpr2/components/MDTypography';

// Timeline context
import { useTimeline } from 'mdpr2/examples/Timeline/context';

// Custom styles for the TimelineItem
import timelineItemRich from 'mdpr2/examples/Timeline/TimelineItem/styles';
import Tooltip from '@mui/material/Tooltip';

function TimelineItemRich({ color, icon, title, dateTime, description, lastItem, tooltip }) {
  const isDark = useTimeline();
  const shipmentIconColor = color;

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItemRich(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={shipmentIconColor}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
          {title}&nbsp;{tooltip !== '' && (<Tooltip title={tooltip} sx={{cursor: 'pointer'}}><WarningIcon /></Tooltip>)}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? 'secondary' : 'text'}>
            {dateTime}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDTypography variant="button" color={isDark ? 'white' : 'dark'}>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItemRich.defaultProps = {
  color: 'info',
  lastItem: false,
  description: '',
  dateTime: null,
  tooltip: '',
};

// Typechecking props for the TimelineItem
TimelineItemRich.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  dateTime: PropTypes.string,
  description: PropTypes.element,
  lastItem: PropTypes.bool,
};

export default TimelineItemRich;
