import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import MDBox from 'mdpr2/components/MDBox';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import MDTypography from 'mdpr2/components/MDTypography';

import { requestApi } from 'api/request-api';
import LearningGroupConfiguration from '../LearningGroupConfiguration';
import MisTeachers from '../MisTeachers';
import MisVerifications from '../MisVerifications';

const InfinityConfiguration = () => {
  const [learningGroup, setLearningGroup] = useState(null);

  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const getResource = async () => {
    const validLearningGroupTypes = ['custom', 'school'];
    const {learningGroupType} = params;
    if (!validLearningGroupTypes.includes(learningGroupType)) {
      toast.error(`${learningGroupType} is not a valid learning group type`);
      return;
    }
    requestApi
      .getResponse({
        url: `${learningGroupType}-learning-groups/${id}`,
      })
      .then((response) => {
        setLearningGroup(response);
      })
      .catch(() => {
        toast.error('Could not get learning group info');
      });
  };


  useEffect(async () => {
    await getResource();
  }, []);

  return (
    <>
      {learningGroup && (
        <MDBox m={2}>
          <MDTypography variant="h5">{learningGroup.name}</MDTypography>
        </MDBox>)}
      {id && (
        <>
          <MDBox m={2}>
            <Card>
              <CardContent>
                <LearningGroupConfiguration learningGroupId={id} />
              </CardContent>
            </Card>
          </MDBox>
          <MDBox m={2}>
            <Card>
              <CardContent title="Learning Group Accesses">
                <MisVerifications learningGroupId={id} />
              </CardContent>
            </Card>
          </MDBox>
          <MDBox m={2}>
            <Card>
              <CardContent>
                <MisTeachers learningGroupId={id} />
              </CardContent>
            </Card>
          </MDBox>
        </>
      )}
    </>
  );
};


export default InfinityConfiguration;
