import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import { useFormik } from 'formik';

export const EditStockChangeModal = ({ onClose, onSubmit, stockChange }) => {
  const formik = useFormik({
    initialValues: {
      quantity: stockChange.quantity,
    },
    onSubmit: (values) => {
      if (values.quantity < 0) {
        return;
      }
      if (!Number.isInteger(values.quantity)) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      stockChange.quantity = values.quantity;
      onSubmit(stockChange, values);
    },
  });

  return (
    <Dialog open aria-labelledby="edit-stock-change" aria-describedby="edit-stock-change" fullWidth maxWidth="sm">
      <DialogTitle id="edit-stock-change-title">Edit this stock change</DialogTitle>
      <DialogContent padding={20}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container paddingTop={1}>
            <Grid item md={12} xs={12}>
              <TextField
                value={formik.values.quantity}
                onChange={formik.handleChange}
                id="quantity"
                label="Quantity"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <MDButton
              color="secondary"
              onClick={onClose}
              variant="gradient"
              data-testid="stock-change-delete-modal-cancel"
              disabled={formik.isSubmitting}
            >
              Cancel
            </MDButton>
            <MDButton
              type="submit"
              color="info"
              variant="gradient"
              data-testid="delete-stock-change-modal-submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? <CircularProgress color='white' size='1.3rem' /> : 'Save'}
            </MDButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

EditStockChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  stockChange: PropTypes.object.isRequired,
};

export default EditStockChangeModal;
