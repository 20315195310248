/* eslint-disable */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

// MDPR
import MDButton from 'mdpr2/components/MDButton';
import MDInput from 'mdpr2/components/MDInput';
import MDBox from 'mdpr2/components/MDBox';
import MDAlertRoot from 'mdpr2/components/MDAlert/MDAlertRoot';

const ConfirmModal = (props) => {
  const {
    body,
    callbackData,
    confirmCallback,
    hasNote,
    open,
    setOpen,
    title,
    modalConfirmOrderRef,
    warning = null,
    disableConfirm = false,
    isDelete = false,
  } = props;

  const [note, setNote] = useState();
  const [yesButtonEnabled, setYesButtonEnabled] = useState(true);
  const [orderRefConfirm, setOrderRefConfirm] = useState('');
  const orderRefConfirmationOK = modalConfirmOrderRef === null || orderRefConfirm.trim() == modalConfirmOrderRef;

  useEffect(() => {
    setOrderRefConfirm('');
    setYesButtonEnabled(true);
  }, [open]);

  const yesClicked = () => {
    // don't allow unless the order number confirmation matches...
    if (!orderRefConfirmationOK) {
      return;
    }

    // prevent double-clicking of the yes button triggering the callback twice
    setYesButtonEnabled(false);
    confirmCallback(note, callbackData);
    setNote(undefined);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-with-note"
      aria-describedby="modal-with-note"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {warning && (
          <MDAlertRoot ownerState={'warning'} sx={{ background: '#FF4B4B'}} >
            <MDBox display="flex" alignItems="center" color="white">
              {warning}
            </MDBox>
          </MDAlertRoot>
        )}
        <p>{body}</p>
        {hasNote && (
          <TextField
            multiline
            variant="outlined"
            fullWidth
            rows={4}
            placeholder="Note (optional)"
            onChange={(e) => setNote(e.target.value)}
          />
        )}
        {modalConfirmOrderRef && (
          <Box sx={{ mt: 2 }}>
            <p>
              To proceed, please confirm the order number...
            </p>
            <MDInput
              variant="outlined"
              error={!orderRefConfirmationOK}
              success={orderRefConfirmationOK}
              placeholder="Confirm Order Ref"
              onChange={(e) => setOrderRefConfirm(e.target.value)}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          color={isDelete ? 'error' : 'info'}
          onClick={() => yesClicked()}
          variant="gradient"
          disabled={disableConfirm ? disableConfirm : !yesButtonEnabled}
        >
          Yes
        </MDButton>
        <MDButton
          color="secondary"
          onClick={() => setOpen(false)}
          variant="gradient"
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  body: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  callbackData: PropTypes.object,
  confirmCallback: PropTypes.func,
  hasNote: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string.isRequired,
  modalConfirmOrderRef: PropTypes.number,
};

ConfirmModal.defaultProps = {
  callbackData: {},
  confirmCallback: () => {
  },
  hasNote: false,
  open: false,
  setOpen: () => {
  },
  modalConfirmOrderRef: null,
};

export default ConfirmModal;
