import { Formik, Form } from 'formik';
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip, Box } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import useMounted from 'hooks/use-mounted';
import { debounce } from "lodash";
import { toast } from 'react-hot-toast';
import Icon from '@mui/material/Icon';
import EyeIcon from '@mui/icons-material/Visibility';
import MDTypography from 'mdpr2/components/MDTypography';

// WRM
import getDateLocaleString from 'utils/get-date-locale-string';
import { useAppContext } from 'contexts/app-context';
import { resourceApi } from 'api/resource-api';
import FormField from 'components/shared/FormField';
import { requestApi } from 'api/request-api';
import WondePreviewModal from './WondePreviewModal';

const LearningGroupConfiguration = ({ learningGroupId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMisSchoolsRefreshing, setIsMisSchoolsRefreshing] = useState(false);
  const [wondePreviewModalOpen, setWondePreviewModalOpen] = useState(false);
  const { setShowLoadingSpinner } = useAppContext();

  const [values, setValues] = useState([]);

  const [initialValues, setInitialValues] = useState({
    misSchool: null,
    isImporting: false,
    lastImportedAt: 'never',
    importGroups: false,
    importClasses: false,
    groupTypes: [],
    id: null,
    shareCode: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const getApiEndpoint = 'infinity/admin/learning-group-configurations';
  const putPostApiEndpoint = 'infinity/admin/learning-group-configuration';

  const misSchoolSearch = (search, setOptions) => {
    if (!search.length) {
      setOptions([]);
      return;
    }
    requestApi.getResponse(
      { 'url': `admin/infinity/mis-schools/choices?search=${encodeURIComponent(search)}` }
    ).then(response => {

      if (!Array.isArray(response)) {
        setOptions([]);
        return
      }

      const choices = response.map((row) => ({
        id: row.value,
        label: row.label
      }));

      setOptions(choices);
    });
  };

  const debouncedMisSchoolSearch = useMemo(
    () => debounce((search, setOptions) => misSchoolSearch(search, setOptions), 280),
    []
  );

  const handleRefreshMisSchoolsButtonClick = () => {
    setIsMisSchoolsRefreshing(true);
    requestApi.getResponse(
      { 'url': `admin/infinity/mis-schools/refresh` }
    ).then(() => {
      toast.success('Accessible MIS schools have been refreshed.');
      setIsMisSchoolsRefreshing(false);
    }).catch((error) => {
      toast.error('Error refreshing accessible MIS schools.');
      console.error('Error refreshing accessible Mis schools:', error);
      setIsMisSchoolsRefreshing(false);
    }
    );
  };

  const groupTypeChoices = [
    {
      value: 'YEAR',
      label: 'YEAR',
    },
    {
      value: 'REGISTRATION',
      label: 'REGISTRATION',
    },
    {
      value: 'HOUSE',
      label: 'HOUSE',
    },
    {
      value: 'BOARDING',
      label: 'BOARDING',
    },
    {
      value: 'COURSE',
      label: 'COURSE',
    },
    {
      value: 'MISC',
      label: 'MISC',
    },
    {
      value: 'USER',
      label: 'USER',
    },
    {
      value: 'CAMPUS',
      label: 'CAMPUS',
    },
    {
      value: 'DIVISION',
      label: 'DIVISION',
    },
    {
      value: 'DEPARTMENT',
      label: 'DEPARTMENT',
    },
  ];

  const fields = [
    {
      name: 'shareCode',
      label: 'Share Code',
      disabled: true
    },
    {
      name: 'misSchool',
      label: 'MIS School Id',
      type: 'typeahead',
      search: debouncedMisSchoolSearch
    },
    {
      name: 'importGroups',
      label: 'Import groups',
      type: 'checkbox',
    },
    {
      name: 'importClasses',
      label: 'Import classes',
      type: 'checkbox',
    },
    {
      name: 'groupTypes',
      label: 'Group types',
      type: 'selectAsTable',
      choices: groupTypeChoices,
    },
  ];

  const getResource = async () => {
    try {
      const apiResource = await resourceApi.getResources({
        apiEndpoint: getApiEndpoint,
        filterValues: { learningGroup: learningGroupId }
      }).catch((error) => {
        toast.error(error.toString());
      });
      if (apiResource.resourceCount < 1) {
        return;
      }
      // this is a collection get endpoint but we assume 0 or 1 resources
      const resource = apiResource.resources[0];
      if (Object.keys(resource).length > 0) {
        setInitialValues({
          misSchool: resource.misSchool
            ? { id: `/mis-schools/${resource.misSchool.id}`, label: resource.misSchool.externalId }
            : null,
          isImporting: resource.misSchool?.isImporting ? 'Yes' : 'No',
          lastImportedAt: resource.misSchool?.lastImportedAt
            ? getDateLocaleString(resource.misSchool.lastImportedAt)
            : 'Never',
          importGroups: resource.importGroups ?? false,
          importClasses: resource.importClasses ?? false,
          groupTypes: resource.groupTypes ?? [],
          id: resource.id ?? null,
          shareCode: resource.shareCode ?? '',
        });
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setIsLoaded(true);
    }
  };

  const isMounted = useMounted();
  useEffect(() => {
    getResource();
  }, [isMounted]);

  const updateResource = async (updatedValues) => {
    const { id, misSchool, importGroups, importClasses, groupTypes } = updatedValues;
    setIsSubmitting(true);
    const data = {
      misSchool: misSchool && misSchool.id ? misSchool.id : null,
      importGroups,
      importClasses,
      groupTypes,
      learningGroup: `learning-groups/${learningGroupId}`,
    };
    try {
      // post if new configuration (id is undefined), else put
      const response = await resourceApi.saveResource({
        apiEndpoint: putPostApiEndpoint,
        data,
        id,
      });
      // update id so next update will be put
      values.id = response.resource.id;
      setInitialValues({
        misSchool: response.resource.misSchool
          ? { id: `/mis-schools/${response.resource.misSchool.id}`, label: response.resource.misSchool.externalId }
          : null,
        isImporting: response.resource.misSchool?.isImporting ? 'Yes' : 'No',
        lastImportedAt: response.resource.misSchool?.lastImportedAt
          ? getDateLocaleString(response.resource.misSchool.lastImportedAt)
          : 'Never',
        importGroups: response.resource.importGroups ?? false,
        importClasses: response.resource.importClasses ?? false,
        groupTypes: response.resource.groupTypes ?? [],
        id: response.resource.id ?? null,
        shareCode: response.resource.shareCode ?? '',
      });
      toast.success('Infinity configuration saved');
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenWondePreviewModal = (formValues) => {
    if (formValues.misSchool === null) {
      toast.error('No Mis school set');
    } else {
      setValues(formValues);
      setWondePreviewModalOpen(true);
    }
  };
  const handleImportClick = async () => {
    if (initialValues.misSchool == null) {
      toast.error('No Mis school set');
      return;
    }
    try {
      setShowLoadingSpinner(true);
      await requestApi.postResponse({
        url: `infinity${initialValues.misSchool.id}/import`,
        data: null,
      });
      toast.success('Import queued, this may take a few minutes', { duration: 6000 });
      setInitialValues({ ...initialValues, isImporting: 'Yes' });
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setShowLoadingSpinner(false);
    }
  };

  return (
    <Box>
      {isLoaded && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(submittedValues) => updateResource(submittedValues)}
        >
          {(formik) => (
            <Form>
              <Grid container mt={1} spacing={2}>
                {fields.map((el, i) => (
                  <Grid item xs={12} mr={1} key={el.name || i} style={{ display: 'flex', alignItems: 'center' }}>
                    <FormField
                      {...fields.find((element) => element.name === fields[i].name)}
                      formik={formik}
                      getApiError={() => false}
                    />
                    {el.name === 'misSchool' && (
                      <>
                        <Tooltip title="Refresh accessible schools" enterDelay={500} arrow>
                          <MDButton
                            color="info"
                            iconOnly
                            variant="gradient"
                            onClick={handleRefreshMisSchoolsButtonClick}
                            disabled={isMisSchoolsRefreshing}
                            style={{ marginLeft: '8px' }}
                          >
                            <Icon>refresh</Icon>
                          </MDButton>
                        </Tooltip>
                        <Tooltip title="Preview current configuration with filters" enterDelay={500} arrow>
                          <MDButton
                            color="info"
                            iconOnly
                            variant="gradient"
                            onClick={() => handleOpenWondePreviewModal(formik.values)}
                            style={{ marginLeft: '8px' }}
                          >
                            <EyeIcon />
                          </MDButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <MDTypography variant="body2">Last imported at: {initialValues.lastImportedAt}</MDTypography>
                  <MDTypography variant="body2">Is importing?: {initialValues.isImporting}</MDTypography>
                </Grid>
                <Grid item xs={2}>
                  <MDButton
                    color="info"
                    disabled={isSubmitting}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    variant="gradient"
                    fullWidth
                  >
                    Save
                  </MDButton>
                </Grid>
                <Grid item xs={2}>
                  <MDButton color="secondary" onClick={() => handleImportClick()} variant="gradient" fullWidth>
                    Import
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {wondePreviewModalOpen && (
        <WondePreviewModal
          setWondePreviewModalOpen={setWondePreviewModalOpen}
          misSchoolExternalId={values.misSchool?.label ?? ''}
          importGroups={values.importGroups}
          importClasses={values.importClasses}
          groupTypes={values.groupTypes}
        />
      )}
    </Box>
  );
};

LearningGroupConfiguration.propTypes = {
  learningGroupId: PropTypes.number.isRequired,
};

export default LearningGroupConfiguration;
